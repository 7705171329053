import React from 'react';
import BaseAuthIFrame from '~/components/LoginRegister/AuthIFrame/BaseAuthIFrame';
import { useSession } from '~/contexts/SessionContext';

interface Props {
  initialView?: 'login' | 'register';
  onSuccessfulLogin: () => void;
  skipJoinOnboarding?: boolean;
}

const AuthIframe: React.FC<Props> = ({
  initialView,
  onSuccessfulLogin,
  skipJoinOnboarding,
}) => {
  const session = useSession();

  return (
    <BaseAuthIFrame
      clientId={session.clientId}
      handleLogin={async event => {
        await session.login(event.accessToken!, event.refreshToken!);
        onSuccessfulLogin();
      }}
      skipJoinOnboarding={skipJoinOnboarding}
      defaultView={initialView}
    />
  );
};

export default AuthIframe;

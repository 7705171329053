import { selectEnv } from 'common-nextjs';
import { useEffect, useRef, useTransition } from 'react';
import { qsStringify } from 'utils';
import {
  fireJoinedEvent,
  fireSignedInEvent,
} from '~/services/analytics/events/auth';

const IFRAME_URL = selectEnv(
  'https://auth.sidelineswap.com',
  'https://auth.staging.sidelineswap.com',
);

interface ParentHeightResponseEvent {
  height: number;
}

type DestinationHint = 'login' | 'join';

export interface ParentSuccessfulLoginResponseEvent {
  destination: string;
  accessToken?: string;
  refreshToken?: string;
  hint?: DestinationHint;
  referralCode?: string;
  social?: 'google' | 'facebook' | 'apple';
}

export default function BaseAuthIFrame({
  clientId,
  defaultView,
  handleLogin,
  onSuccessfulLogin,
  skipJoinOnboarding,
}: {
  clientId: string;
  defaultView?: 'login' | 'register';
  handleLogin: (event: ParentSuccessfulLoginResponseEvent) => Promise<void>;
  onSuccessfulLogin?: () => void;
  skipJoinOnboarding?: boolean;
}) {
  const ref = useRef<HTMLIFrameElement>(null);
  const [isPending, startTransition] = useTransition();

  const iframeBaseUrl = defaultView
    ? IFRAME_URL + '/' + defaultView
    : IFRAME_URL;

  const iframeParams = {
    platform: 'iframe',
    client_id: clientId,
  };

  useEffect(() => {
    const iframe = ref.current;

    async function messageListener(event: MessageEvent) {
      if (event.origin !== IFRAME_URL) {
        return;
      }

      try {
        const data:
          | ParentHeightResponseEvent
          | ParentSuccessfulLoginResponseEvent = JSON.parse(event.data);

        if ('height' in data && data.height && iframe) {
          iframe.style.height = data.height + 'px';
        } else if ('accessToken' in data) {
          startTransition(async () => {
            await handleLogin(data);

            if (data.hint === 'login') {
              fireSignedInEvent();
            } else {
              const socialType =
                data.social === 'apple'
                  ? 'Apple'
                  : data.social === 'google'
                    ? 'Google'
                    : data.social === 'facebook'
                      ? 'Facebook'
                      : 'Login and Password';
              await fireJoinedEvent(socialType, data.referralCode, data.social);

              if (!skipJoinOnboarding) {
                window.location.assign('/register/onboarding');
              }
            }

            if (onSuccessfulLogin && !skipJoinOnboarding) {
              startTransition(() => {
                onSuccessfulLogin();
              });
            }
          });
        }
      } catch (err) {}
    }

    window.addEventListener('message', messageListener);

    return () => {
      window.removeEventListener('message', messageListener);
    };
  }, []);

  return (
    <iframe
      src={iframeBaseUrl + qsStringify(iframeParams)}
      className="w-full"
      ref={ref}
      style={{
        height: 400,
      }}
    />
  );
}

import { useRouter } from 'next/router';
import Layout from '~/components/Layout';
import AuthIframe from '~/components/LoginRegister/AuthIFrame/AuthIframe';

const LoginPage = () => {
  const router = useRouter();
  return (
    <Layout title="Login">
      <AuthIframe
        onSuccessfulLogin={() =>
          router.replace(
            decodeURIComponent((router.query.from as string) || '/'),
          )
        }
      />
    </Layout>
  );
};

export default LoginPage;
